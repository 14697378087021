body {
    font-family: 'BlinkMacSystemFont', 'Segoe UI', 'Roboto', 'Helvetica', 'Arial', 'Segoe UI Emoji', 'Segoe UI Symbol', sans-serif;
    color: #1c1c1c;
    background-color: #ffffff;
    font-size: 20px;
    text-align: center;
    margin: 0;
}

p {
    margin: 25px 0;
}

a {
    text-decoration: underline;
    color: #1c1c1c;
}

button {
    display: block;
    box-sizing: border-box;
    width: 100%;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 20px;
    margin: 25px 0;
    background-color: #102695;
    color: #ffffff;
    border: 0;
    padding: 28px 40px;
}

button:disabled {
    background-color: #10269580;
    border: 0;
}

.link {
    text-decoration: underline;
    text-transform: none;
    font-weight: normal;
    color: #1c1c1c;
    box-sizing: border-box;
    background: none;
    padding: 0;
    margin: 0;
}

a.button {
    display: block;
    text-transform: uppercase;
    text-decoration: none;
    font-weight: bold;
    margin: 25px 0;
    background-color: #102695;
    color: #ffffff;
    border: 0;
    padding: 28px 40px;
    cursor: default;
}

a.button.disabled {
    background-color: #102695;
    border: 0;
}

ul {
    list-style-type: none;
    padding: 0;
    margin: 0;
}

ul li {
    margin-bottom: 20px;
}

.main {
    padding: 20px 38px 10px 38px;
}

.logout {
    margin-top: 20px;
    background-color: #f0f0f0;
    padding: 8px;
}

.logout p {
    margin: 0 0 8px 0;
}

.leaveProject {
    margin-top: 20px;
    background-color: #f0f0f0;
    padding: 8px;
}

.leaveProject p {
    margin: 0 0 8px 0;
}