div.static-text {
    display: block;
    border: 2px solid #1c1c1c;
    text-align: left;
    padding: 20px;
    margin: 25px 0;
}

div.static-text.disabled {
    color: #1c1c1c80;
}

div.static-text .label {
    display: block;
    font-size: 14px;
    font-weight: bold;
}

div.static-text .value {
    display: block;
    width: 100%;
    border: 0;
    font-size: 20px;
    font-weight: bold;
    margin-top: 2px;
    overflow-wrap: break-word;
}
