.prev-project .select__control {
    background-color: white;
    border: 2px solid #1c1c1c;
    text-align: left;
    padding: 10px 20px;
    margin: 25px 0;
    border-radius: 0;
}

.prev-project .select__control:hover {
    border-color: inherit;
}

.prev-project .select__value-container {
    padding: 20px 0;
}

.prev-project .select__single-value {
    margin: 0;
}

.prev-project .select__placeholder {
    font-size: 18px;
    font-weight: bold;
    margin: 0;
    color: #1c1c1c80;
}

.prev-project .select__indicator-separator {
    width: 0;
}

.prev-project span {
    display: block;
    text-align: left;
}

.prev-project .number {
    font-weight: bold;
    font-size: 18px;
}

.prev-project .description {
    font-size: 16px;
}

.prev-project .customer {
    font-size: 14px;
    font-weight: bold;
}

.prev-project.disabled {
    display: block;
    border: 2px solid #1c1c1c80;
    color: #1c1c1c80;
    font-weight: bold;
    text-align: left;
    padding: 30px 20px;
    margin: 25px 0;
    font-size: 18px;
}

@media screen and (pointer: coarse) and (hover: none) {
    .prev-project .select__option--is-focused {
        background: none;
        color: black;
    }
}

.prev-project .select__option--is-selected {
    background: #102695;
    color: white;
}
