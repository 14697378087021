label.date-of-birth {
    display: block;
    border: 2px solid #1c1c1c;
    text-align: left;
    padding: 20px;
    margin: 25px 0;
}

label.date-of-birth.disabled {
    color: #1c1c1c80;
}

label.date-of-birth .label {
    display: block;
    font-size: 14px;
    font-weight: bold;
}

label.date-of-birth input {
    border: 0;
    border-bottom: 1px solid #1c1c1c80;
    padding: 2px;
    font-size: 20px;
    font-weight: bold;
    margin: 2px 4px 0 4px;
}

label.date-of-birth input:disabled {
    background: inherit;
}

.date-of-birth .day {
    width: 1.75em;
    text-align: center;
}

.date-of-birth .month {
    width: 1.75em;
    text-align: center;
}

.date-of-birth .year {
    width: 3em;
    text-align: center;
}
