header {
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 0;
    left: 0;
    background-color: #f0f0f0;
    height: 100px;
    width: 100%;
    z-index: 1;
}

header .logo {
    float: left;
    width: 131px;
}

header .title {
    text-align: center;
    height: 100%;
    overflow: hidden;
}

header .title span {
    line-height: 100px;
    vertical-align: middle;
}
