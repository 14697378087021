.employment-type .select__control {
    background-color: white;
    border: 2px solid #1c1c1c;
    text-align: left;
    padding: 10px 20px;
    margin: 25px 0;
    border-radius: 0;
}

.employment-type .select__control:hover {
    border-color: inherit;
}

.employment-type .select__value-container {
    padding: 20px 0;
}

.employment-type .select__single-value {
    margin: 0;
}

.employment-type .select__placeholder {
    font-size: 20px;
    font-weight: bold;
    margin: 0;
    color: #1c1c1c80;
}

.employment-type .select__indicator-separator {
    width: 0;
}

.employment-type .select__menu {
    bottom: 100%;
    top: auto;
}

.employment-type span {
    display: block;
    text-align: left;
}

.employment-type .description {
    font-weight: bold;
    font-size: 20px;
}

@media screen and (pointer: coarse) and (hover: none) {
    .employment-type .select__option--is-focused {
        background: none;
        color: black;
    }
}

.employment-type .select__option--is-selected {
    background: #102695;
    color: white;
}
