.barcode-scanner {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 2;
    padding: 0;
    margin: 0;
    width: 100%;
    height: 100%;
}

.barcode-scanner video, .barcode-scanner canvas {
    position: relative;
    width: 100%;
    height: 100%;
}

.barcode-scanner video {
    object-fit: fill;
}

.barcode-result {
    z-index: 10;
    display: inline-block;
}

.login-card {
    position: fixed;
    display: flex;
    flex-direction: column;
    border: 1px red solid;
    left: 0;
    right: 0;
}